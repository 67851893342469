import React from 'react'
import {Container, Row, Col} from 'reactstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {Pricing} from "../components/pricing";
import SemantischeTextvorlagenS1Image from "../components/SemantischeTextvorlagenS1Image";
import SemantischeTextvorlagenS2Image from "../components/SemantischeTextvorlagenS2Image";
import SemantischeTextvorlagenS3Image from "../components/SemantischeTextvorlagenS3Image";
import {withPrefix} from "gatsby-link";

const BefundeUndRezepte = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - Semantische Textvorlagen"
             googleDescription="Medoboard bietet mit semantischen Textvorlagen optimiertes Datenmanagement und vereinfacht die Patientenverwaltung damit signifikant."
             fbUrl="https://medoboard.com/semantische-textvorlagen"
             fbImage={withPrefix('semantische-textvorlagen-fb.png')}
             fbTitle="Medoboard - Semantische Textvorlagen"
             fbDescription="Medoboard bietet mit semantischen Textvorlagen optimiertes Datenmanagement und vereinfacht die Patientenverwaltung damit signifikant."
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`, `Textvorlagen`, `Semantische`]}/>
        <Container>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Semantische Textvorlagen</h2>
                    <h4 className="text-muted">Mit einem Klick fertig.</h4>
                    <p className="lead">
                        Medoboard bietet mit semantischen Textvorlagen optimiertes Datenmanagement und vereinfacht die Patientenverwaltung damit signifikant.
                    </p>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="8">
                    <SemantischeTextvorlagenS1Image/>
                </Col>
                <Col md="4" className="px-5">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Schritt 1: Textvorlagen anlegen</h2>
                            <p>
                                Jedes einzelne Eingabefeld kann in medoboard mit eigenen Texten belegt werden. Sie können dazu einfach unter
                            </p>
                            <p><b>Vorlagen > Textbausteine</b></p>
                            <p>editiert werden.</p>
                            <p>In diesem Beispiel wird für das Textfeld <b>Visite->Medikation</b> ein neuer Eintrag für <b>Viropel_Prophylaxe</b> angelegt.</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="4" className="px-5">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Schritt 2: Textvorlagen verwenden</h2>
                            <p>
                                Bei der Erstellung der Visite steht nun die zuvor erstellte Textvorlage im Textfeld
                            </p>
                            <p><b>Visiten > Medikation</b></p>
                            <p>zur Schnellauswahl zur Verfügung.</p>
                        </div>
                    </div>
                </Col>
                <Col md="8">
                    <SemantischeTextvorlagenS2Image/>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="12">
                    <SemantischeTextvorlagenS3Image/>
                </Col>
            </Row>
            <Row className="carousel">
                <Col md="12" className="px-5">
                    <div className="featurette-content">
                        <div>
                            <h2 className="my-4">Schritt 3: Text übernehmen</h2>
                            <p>Mit einem Klick kann der gesamte Text </p>
                            <p>(<b>Im akuten Stadium Viropel 500mg 4-0-4 als Eintagestherapie</b>)</p>
                            <p>eingetragen werden.</p>
                        </div>
                    </div>
                </Col>
            </Row>
            <h2 className="my-4">Mit einem Klick zum Ergebnis</h2>
            <p>
                Semantische Textvorlagen optimieren die Dateneingabe und verbessern die Datenqualität signifikant.
            </p>
            <p>
                Testen Sie medoboard noch heute kostenlos und unverbindich!
            </p>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
);

export default BefundeUndRezepte;
